export enum ProductEnum {
  Chronicle = 'Chronicle',
  HumanMadeMachine = 'HumanMadeMachine',
  Incubator = 'Incubator',
  Pulse = 'Pulse',
  Radar = 'Radar',
}

export const productNames = {
  [ProductEnum.Chronicle]: 'Chronicle',
  [ProductEnum.HumanMadeMachine]: 'Human Made Machine',
  [ProductEnum.Incubator]: 'Incubator',
  [ProductEnum.Pulse]: 'Pulse',
  [ProductEnum.Radar]: 'Radar',
};

export const productLogos = {
  [ProductEnum.Chronicle]:
    'https://assets.humanmademachine.com/hmm/logos/white/product/Chronicle_Icon_White.svg',
  [ProductEnum.HumanMadeMachine]:
    'https://assets.humanmademachine.com/hmm/logos/white/brand/HMM_Lock_Up_White.svg',
  [ProductEnum.Incubator]:
    'https://assets.humanmademachine.com/hmm/logos/white/product/Incubator_Icon_White.svg',
  [ProductEnum.Pulse]:
    'https://assets.humanmademachine.com/hmm/logos/white/product/Pulse_Icon_White.svg',
  [ProductEnum.Radar]:
    'https://assets.humanmademachine.com/hmm/logos/white/product/Radar_Icon_White.svg',
};

export const productSmallLogos = {
  [ProductEnum.Chronicle]:
    'https://assets.humanmademachine.com/hmm/logos/white/product/Chronicle_Icon_White.svg',
  [ProductEnum.HumanMadeMachine]:
    'https://assets.humanmademachine.com/hmm/logos/white/brand/HMM_Marque_White.svg',
  [ProductEnum.Incubator]:
    'https://assets.humanmademachine.com/hmm/logos/white/product/Incubator_Icon_White.svg',
  [ProductEnum.Pulse]:
    'https://assets.humanmademachine.com/hmm/logos/white/product/Pulse_Icon_White.svg',
  [ProductEnum.Radar]:
    'https://assets.humanmademachine.com/hmm/logos/white/product/Radar_Icon_White.svg',
};

export const productUrls = {
  [ProductEnum.Chronicle]: 'https://chronicle.humanmademachine.com',
  [ProductEnum.HumanMadeMachine]: 'https://www.humanmademachine.com',
  [ProductEnum.Incubator]: 'https://dashboard.humanmademachine.com',
  [ProductEnum.Pulse]: 'https://pulse.humanmademachine.com',
  [ProductEnum.Radar]: 'https://radar.humanmademachine.com',
};
