import * as Sentry from '@sentry/browser';
import { SDK_VERSION, defaultIntegrations, init as init$1, setContext, WINDOW, getCurrentHub } from '@sentry/browser';
export * from '@sentry/browser';
import * as i0 from '@angular/core';
import { VERSION, Injectable, Inject, Directive, Input, NgModule } from '@angular/core';
import { logger, isString, addExceptionMechanism, stripUrlQueryAndFragment, timestampInSeconds } from '@sentry/utils';
import { HttpErrorResponse } from '@angular/common/http';
import * as i1 from '@angular/router';
import { NavigationStart, ResolveEnd, NavigationEnd, NavigationCancel, NavigationError, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

/*
 * This file defines flags and constants that can be modified during compile time in order to facilitate tree shaking
 * for users.
 *
 * We define "magic strings" like `__SENTRY_DEBUG__` that may get replaced with actual values during our, or the user's
 * build process. Take care when introducing new flags - they must not throw if they are not replaced. See the Debug
 * Build Flags section in CONTRIBUTING.md.
 */
/** Flag that is true for debug builds, false otherwise. */
const IS_DEBUG_BUILD = typeof __SENTRY_DEBUG__ === 'undefined' ? true : __SENTRY_DEBUG__;

/**
 * Inits the Angular SDK
 */
function init(options) {
    options._metadata = options._metadata || {};
    options._metadata.sdk = {
        name: 'sentry.javascript.angular',
        packages: [
            {
                name: 'npm:@sentry/angular',
                version: SDK_VERSION,
            },
        ],
        version: SDK_VERSION,
    };
    // Filter out TryCatch integration as it interferes with our Angular `ErrorHandler`:
    // TryCatch would catch certain errors before they reach the `ErrorHandler` and thus provide a
    // lower fidelity error than what `SentryErrorHandler` (see errorhandler.ts) would provide.
    // see:
    //  - https://github.com/getsentry/sentry-javascript/issues/5417#issuecomment-1453407097
    //  - https://github.com/getsentry/sentry-javascript/issues/2744
    if (options.defaultIntegrations === undefined) {
        options.defaultIntegrations = defaultIntegrations.filter(integration => {
            return integration.name !== 'TryCatch';
        });
    }
    checkAndSetAngularVersion();
    init$1(options);
}
function checkAndSetAngularVersion() {
    const ANGULAR_MINIMUM_VERSION = 10;
    const angularVersion = VERSION && VERSION.major ? parseInt(VERSION.major, 10) : undefined;
    if (angularVersion) {
        if (angularVersion < ANGULAR_MINIMUM_VERSION) {
            IS_DEBUG_BUILD &&
                logger.warn(`The Sentry SDK does not officially support Angular ${angularVersion}.`, `This version of the Sentry SDK supports Angular ${ANGULAR_MINIMUM_VERSION} and above.`, 'Please consider upgrading your Angular version or downgrading the Sentry SDK.');
        }
        setContext('angular', { version: angularVersion });
    }
}

// There're 2 types of Angular applications:
// 1) zone-full (by default)
// 2) zone-less
// The developer can avoid importing the `zone.js` package and tells Angular that
// he is responsible for running the change detection by himself. This is done by
// "nooping" the zone through `CompilerOptions` when bootstrapping the root module.
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
const isNgZoneEnabled = typeof Zone !== 'undefined' && !!Zone.current;
/**
 * The function that does the same job as `NgZone.runOutsideAngular`.
 */
function runOutsideAngular(callback) {
    // The `Zone.root.run` basically will run the `callback` in the most parent zone.
    // Any asynchronous API used inside the `callback` won't catch Angular's zone
    // since `Zone.current` will reference `Zone.root`.
    // The Angular's zone is forked from the `Zone.root`. In this case, `zone.js` won't
    // trigger change detection, and `ApplicationRef.tick()` will not be run.
    // Caretaker note: we're using `Zone.root` except `NgZone.runOutsideAngular` since this
    // will require injecting the `NgZone` facade. That will create a breaking change for
    // projects already using the `@sentry/angular`.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return isNgZoneEnabled ? Zone.root.run(callback) : callback();
}

// https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
function tryToUnwrapZonejsError(error) {
    // TODO: once Angular14 is the minimum requirement ERROR_ORIGINAL_ERROR and
    //  getOriginalError from error.ts can be used directly.
    return error && error.ngOriginalError
        ? error.ngOriginalError
        : error;
}
function extractHttpModuleError(error) {
    // The `error` property of http exception can be either an `Error` object, which we can use directly...
    if (isErrorOrErrorLikeObject(error.error)) {
        return error.error;
    }
    // ... or an`ErrorEvent`, which can provide us with the message but no stack...
    if (error.error instanceof ErrorEvent && error.error.message) {
        return error.error.message;
    }
    // ...or the request body itself, which we can use as a message instead.
    if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
    }
    // If we don't have any detailed information, fallback to the request message itself.
    return error.message;
}
function isErrorOrErrorLikeObject(value) {
    if (value instanceof Error) {
        return true;
    }
    if (value === null || typeof value !== 'object') {
        return false;
    }
    const candidate = value;
    return (isString(candidate.name) &&
        isString(candidate.message) &&
        (undefined === candidate.stack || isString(candidate.stack)));
}
/**
 * Implementation of Angular's ErrorHandler provider that can be used as a drop-in replacement for the stock one.
 */
class SentryErrorHandler {
    constructor(options) {
        /* indicates if we already registered our the afterSendEvent handler */
        this._registeredAfterSendEventHandler = false;
        this._options = Object.assign({ logErrors: true }, options);
    }
    /**
     * Method called for every value captured through the ErrorHandler
     */
    handleError(error) {
        const extractedError = this._extractError(error) || 'Handled unknown error';
        // Capture handled exception and send it to Sentry.
        const eventId = runOutsideAngular(() => Sentry.captureException(extractedError, (scope) => {
            scope.addEventProcessor(event => {
                addExceptionMechanism(event, {
                    type: 'angular',
                    handled: false,
                });
                return event;
            });
            return scope;
        }));
        // When in development mode, log the error to console for immediate feedback.
        if (this._options.logErrors) {
            // eslint-disable-next-line no-console
            console.error(extractedError);
        }
        // Optionally show user dialog to provide details on what happened.
        if (this._options.showDialog) {
            const client = Sentry.getCurrentHub().getClient();
            if (client && client.on && !this._registeredAfterSendEventHandler) {
                client.on('afterSendEvent', (event) => {
                    if (!event.type) {
                        Sentry.showReportDialog(Object.assign(Object.assign({}, this._options.dialogOptions), { eventId: event.event_id }));
                    }
                });
                // We only want to register this hook once in the lifetime of the error handler
                this._registeredAfterSendEventHandler = true;
            }
            else if (!client || !client.on) {
                Sentry.showReportDialog(Object.assign(Object.assign({}, this._options.dialogOptions), { eventId }));
            }
        }
    }
    /**
     * Used to pull a desired value that will be used to capture an event out of the raw value captured by ErrorHandler.
     */
    _extractError(error) {
        // Allow custom overrides of extracting function
        if (this._options.extractor) {
            const defaultExtractor = this._defaultExtractor.bind(this);
            return this._options.extractor(error, defaultExtractor);
        }
        return this._defaultExtractor(error);
    }
    /**
     * Default implementation of error extraction that handles default error wrapping, HTTP responses, ErrorEvent and few other known cases.
     */
    _defaultExtractor(errorCandidate) {
        const error = tryToUnwrapZonejsError(errorCandidate);
        // If it's http module error, extract as much information from it as we can.
        if (error instanceof HttpErrorResponse) {
            return extractHttpModuleError(error);
        }
        // We can handle messages and Error objects directly.
        if (typeof error === 'string' || isErrorOrErrorLikeObject(error)) {
            return error;
        }
        // Nothing was extracted, fallback to default error message.
        return null;
    }
}
SentryErrorHandler.ɵprov = i0.ɵɵdefineInjectable({ factory: function SentryErrorHandler_Factory() { return new SentryErrorHandler(i0.ɵɵinject("errorHandlerOptions")); }, token: SentryErrorHandler, providedIn: "root" });
SentryErrorHandler.decorators = [
    { type: Injectable, args: [{ providedIn: 'root' },] }
];
SentryErrorHandler.ctorParameters = () => [
    { type: undefined, decorators: [{ type: Inject, args: ['errorHandlerOptions',] }] }
];
/**
 * Factory function that creates an instance of a preconfigured ErrorHandler provider.
 */
function createErrorHandler(config) {
    return new SentryErrorHandler(config);
}

const ANGULAR_ROUTING_OP = 'ui.angular.routing';
const ANGULAR_INIT_OP = 'ui.angular.init';
const ANGULAR_OP = 'ui.angular';

let instrumentationInitialized;
let stashedStartTransaction;
let stashedStartTransactionOnLocationChange;
/**
 * Creates routing instrumentation for Angular Router.
 */
function routingInstrumentation(customStartTransaction, startTransactionOnPageLoad = true, startTransactionOnLocationChange = true) {
    instrumentationInitialized = true;
    stashedStartTransaction = customStartTransaction;
    stashedStartTransactionOnLocationChange = startTransactionOnLocationChange;
    if (startTransactionOnPageLoad && WINDOW && WINDOW.location) {
        customStartTransaction({
            name: WINDOW.location.pathname,
            op: 'pageload',
            metadata: { source: 'url' },
        });
    }
}
const instrumentAngularRouting = routingInstrumentation;
/**
 * Grabs active transaction off scope
 */
function getActiveTransaction() {
    const currentHub = getCurrentHub();
    if (currentHub) {
        const scope = currentHub.getScope();
        if (scope) {
            return scope.getTransaction();
        }
    }
    return undefined;
}
/**
 * Angular's Service responsible for hooking into Angular Router and tracking current navigation process.
 * Creates a new transaction for every route change and measures a duration of routing process.
 */
class TraceService {
    constructor(_router) {
        this._router = _router;
        this.navStart$ = this._router.events.pipe(filter((event) => event instanceof NavigationStart), tap(navigationEvent => {
            if (!instrumentationInitialized) {
                IS_DEBUG_BUILD &&
                    logger.error('Angular integration has tracing enabled, but Tracing integration is not configured');
                return;
            }
            const strippedUrl = stripUrlQueryAndFragment(navigationEvent.url);
            let activeTransaction = getActiveTransaction();
            if (!activeTransaction && stashedStartTransactionOnLocationChange) {
                activeTransaction = stashedStartTransaction({
                    name: strippedUrl,
                    op: 'navigation',
                    metadata: { source: 'url' },
                });
            }
            if (activeTransaction) {
                if (this._routingSpan) {
                    this._routingSpan.finish();
                }
                this._routingSpan = activeTransaction.startChild({
                    description: `${navigationEvent.url}`,
                    op: ANGULAR_ROUTING_OP,
                    tags: Object.assign({ 'routing.instrumentation': '@sentry/angular', url: strippedUrl }, (navigationEvent.navigationTrigger && {
                        navigationTrigger: navigationEvent.navigationTrigger,
                    })),
                });
            }
        }));
        // The ResolveEnd event is fired when the Angular router has resolved the URL and
        // the parameter<->value mapping. It holds the new resolved router state with
        // the mapping and the new URL.
        // Only After this event, the route is activated, meaning that the transaction
        // can be updated with the parameterized route name before e.g. the route's root
        // component is initialized. This should be early enough before outgoing requests
        // are made from the new route, with the exceptions of requests being made during
        // a navigation.
        this.resEnd$ = this._router.events.pipe(filter((event) => event instanceof ResolveEnd), tap(event => {
            const route = getParameterizedRouteFromSnapshot(event.state.root);
            const transaction = getActiveTransaction();
            // TODO (v8 / #5416): revisit the source condition. Do we want to make the parameterized route the default?
            if (transaction && transaction.metadata.source === 'url') {
                transaction.setName(route, 'route');
            }
        }));
        this.navEnd$ = this._router.events.pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError), tap(() => {
            if (this._routingSpan) {
                runOutsideAngular(() => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    this._routingSpan.finish();
                });
                this._routingSpan = null;
            }
        }));
        this._routingSpan = null;
        this._subscription = new Subscription();
        this._subscription.add(this.navStart$.subscribe());
        this._subscription.add(this.resEnd$.subscribe());
        this._subscription.add(this.navEnd$.subscribe());
    }
    /**
     * This is used to prevent memory leaks when the root view is created and destroyed multiple times,
     * since `subscribe` callbacks capture `this` and prevent many resources from being GC'd.
     */
    ngOnDestroy() {
        this._subscription.unsubscribe();
    }
}
TraceService.ɵprov = i0.ɵɵdefineInjectable({ factory: function TraceService_Factory() { return new TraceService(i0.ɵɵinject(i1.Router)); }, token: TraceService, providedIn: "root" });
TraceService.decorators = [
    { type: Injectable, args: [{ providedIn: 'root' },] }
];
TraceService.ctorParameters = () => [
    { type: Router }
];
const UNKNOWN_COMPONENT = 'unknown';
/**
 * A directive that can be used to capture initialization lifecycle of the whole component.
 */
class TraceDirective {
    /**
     * Implementation of OnInit lifecycle method
     * @inheritdoc
     */
    ngOnInit() {
        if (!this.componentName) {
            this.componentName = UNKNOWN_COMPONENT;
        }
        const activeTransaction = getActiveTransaction();
        if (activeTransaction) {
            this._tracingSpan = activeTransaction.startChild({
                description: `<${this.componentName}>`,
                op: ANGULAR_INIT_OP,
            });
        }
    }
    /**
     * Implementation of AfterViewInit lifecycle method
     * @inheritdoc
     */
    ngAfterViewInit() {
        if (this._tracingSpan) {
            this._tracingSpan.finish();
        }
    }
}
TraceDirective.decorators = [
    { type: Directive, args: [{ selector: '[trace]' },] }
];
TraceDirective.propDecorators = {
    componentName: [{ type: Input, args: ['trace',] }]
};
/**
 * A module serves as a single compilation unit for the `TraceDirective` and can be re-used by any other module.
 */
class TraceModule {
}
TraceModule.decorators = [
    { type: NgModule, args: [{
                declarations: [TraceDirective],
                exports: [TraceDirective],
            },] }
];
/**
 * Decorator function that can be used to capture initialization lifecycle of the whole component.
 */
function TraceClassDecorator() {
    let tracingSpan;
    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    return target => {
        const originalOnInit = target.prototype.ngOnInit;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        target.prototype.ngOnInit = function (...args) {
            const activeTransaction = getActiveTransaction();
            if (activeTransaction) {
                tracingSpan = activeTransaction.startChild({
                    description: `<${target.name}>`,
                    op: ANGULAR_INIT_OP,
                });
            }
            if (originalOnInit) {
                return originalOnInit.apply(this, args);
            }
        };
        const originalAfterViewInit = target.prototype.ngAfterViewInit;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        target.prototype.ngAfterViewInit = function (...args) {
            if (tracingSpan) {
                tracingSpan.finish();
            }
            if (originalAfterViewInit) {
                return originalAfterViewInit.apply(this, args);
            }
        };
    };
    /* eslint-enable @typescript-eslint/no-unsafe-member-access */
}
/**
 * Decorator function that can be used to capture a single lifecycle methods of the component.
 */
function TraceMethodDecorator() {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/ban-types
    return (target, propertyKey, descriptor) => {
        const originalMethod = descriptor.value;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        descriptor.value = function (...args) {
            const now = timestampInSeconds();
            const activeTransaction = getActiveTransaction();
            if (activeTransaction) {
                activeTransaction.startChild({
                    description: `<${target.constructor.name}>`,
                    endTimestamp: now,
                    op: `${ANGULAR_OP}.${String(propertyKey)}`,
                    startTimestamp: now,
                });
            }
            if (originalMethod) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                return originalMethod.apply(this, args);
            }
        };
        return descriptor;
    };
}
/**
 * Takes the parameterized route from a given ActivatedRouteSnapshot and concatenates the snapshot's
 * child route with its parent to produce the complete parameterized URL of the activated route.
 * This happens recursively until the last child (i.e. the end of the URL) is reached.
 *
 * @param route the ActivatedRouteSnapshot of which its path and its child's path is concatenated
 *
 * @returns the concatenated parameterized route string
 */
function getParameterizedRouteFromSnapshot(route) {
    const parts = [];
    let currentRoute = route && route.firstChild;
    while (currentRoute) {
        const path = currentRoute && currentRoute.routeConfig && currentRoute.routeConfig.path;
        if (path === null || path === undefined) {
            break;
        }
        parts.push(path);
        currentRoute = currentRoute.firstChild;
    }
    const fullPath = parts.filter(part => part).join('/');
    return fullPath ? `/${fullPath}/` : '/';
}

/**
 * Generated bundle index. Do not edit.
 */

export { SentryErrorHandler, TraceClassDecorator, TraceDirective, TraceMethodDecorator, TraceModule, TraceService, createErrorHandler, getActiveTransaction, init, instrumentAngularRouting, routingInstrumentation };

