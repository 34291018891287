<footer>
  <div *ngIf="extended">
    <div class="extended row">
      <div class="col-12 pb-4">
        <h5>Contact us</h5>
        <p class="text-muted">
          If you are interested in working with us or for any more information
          about our solutions or please don't hesitate to get in touch.
        </p>
      </div>
    </div>
    <div class="row row-cols-12">
      <div class="col-lg">
        <h5>UK</h5>
        <p class="text-muted" translate="no">
          Unit 6<br />
          2-4 Hoxton Square<br />
          London<br />
          N1 6NU<br />
          United Kingdom<br />
        </p>
        <p>
          <a class="link-light font-heading" href="tel:+442071849870"
            >+44 20 7184 9870</a
          >
        </p>
      </div>
      <div class="col-lg">
        <h5>USA</h5>
        <p class="text-muted" translate="no">
          501 E Las Olas Blvd<br />
          Ste 300<br />
          Fort Lauderdale<br />
          FL 33301<br />
          USA
        </p>
      </div>
      <div class="col-lg">
        <h5>&nbsp;</h5>
        <p class="text-muted" translate="no">
          1640 Boro Place<br />
          5th Fl, Rm 528<br />
          Mclean<br />
          Virginia 22102<br />
          USA
        </p>
      </div>
      <div class="col-lg">
        <h5>Australia</h5>
        <p class="text-muted" translate="no">
          Suite 84/Level 4<br />
          80 Market Street<br />
          South Melbourne<br />
          VIC 3205<br />
          Australia
        </p>
      </div>
      <div class="col-lg">
        <h5>Singapore</h5>
        <p class="text-muted" translate="no">
          16 Raffles Quay<br />
          #09-01 Hong Leong Building<br />
          Singapore 048581<br />
          Singapore
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <h5>Get in touch</h5>
        <p class="mb-0">
          <a
            class="link-light font-heading"
            href="mailto:talkto@humanmademachine.com"
            >talkto&#64;humanmademachine.com</a
          >
        </p>
      </div>
      <div class="col-12 col-sm-6">
        <h5>Careers</h5>
        <p class="mb-0">
          <a class="link-light font-heading" href="/careers"
            >View Open Positions</a
          >
        </p>
      </div>
    </div>
  </div>
  <div class="row align-items-end">
    <div class="col-auto">
      <p class="text-muted m-0 py-2">&copy;{{ year }} Human Made Machine</p>
    </div>
    <div *ngIf="logo" class="col-auto ms-auto">
      <h2m-product-logo class="small"></h2m-product-logo>
    </div>
  </div>
</footer>
